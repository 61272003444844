import Vue from 'vue'
import Vuex from 'vuex'

import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    curUserName: '',
    globalData: {
      companyName: '',
      companyNameShow: false,  //是否使用企业名称，0：否，使用默认名称，1：是，使用企业名称替换默认名称
      groupListShow: 0,  //是否显示分组列表，0：否，1：是
      curGroupName: '',
      curGroupId: null,
      curMachine: '',
      curOnline: 0,  //在线状态，1：在线，0：不在线
      curUniqueWebcamSerial: '',  //双目摄像头唯一设备编号
      curWebcamSerial: '',        //双目摄像头 序列号 后 追加 1或2
      curStyleSize: null,  //1：智灵钻， 2：智立方， 3：智立方L， 4：单面屏风， 5：双面屏风， 6：智灵宝， 7：工程模组， 8：集装箱
      curProtocolType: null, //1：家用机协议，2：工程模组协议
      curLayerNum: null, //二代家用机层数
      curLayerFan: null, //二代家用机是否有层间风扇， 0：否，1：是
      accessToken: null,
      loginToken: null,
      pumpNum: 1,
      curPumpNo: 1,
      curPumpStatus: 0,  //当前水泵状态，0：关闭，1：开启   用于一代机
      pumpList: null,  //水泵列表：包含模组编号，1-10：模组  11-12:主板
      envSensorNum: 1,
      curEnvSensorNo: 1,
      envSensorList: null,  //环境传感器列表：包含模组编号，0：主机， 1-10：模组
      waterSensorNum: 1,
      curWaterSensorNo: 1,
      waterSensorList: null,  //液体传感器列表：包含模组编号，0：主机， 1-10：模组
      navBarShow: 1,
      quitBtnValid: 1,
      webcamShow: 1,
      webcamType: 0,  //摄像头类型，1：国标GB28181， 2：海康-萤石
      machineLoop: true,  //设备循环展示，false：否，true：是
      province: '',
      region: '',
      longitude: null,
      latitude: null,
      regionList: null
    }
  },
  mutations: {
    setCurUserName(state, data) {
      state.curUserName = data;
    },
    setGlobalData(state, data) {
      state.globalData = data;
    },
    setGroupListShow(state, data) {
      state.globalData.groupListShow = data;
    },
    setCurGroupName(state, data) {
      state.globalData.curGroupName = data;
    },
    setCurGroupId(state, data) {
      state.globalData.curGroupId = data;
    },
    setCurMachine(state, data) {
      state.globalData.curMachine = data;
    },
    setCurOnline(state, data) {
      state.globalData.curOnline = data;
    },
    setCurStyleSize(state, data) {
      state.globalData.curStyleSize = data;
    },
    setCurProtocolType(state, data) {
      state.globalData.curProtocolType = data;
    },
    setCurLayerNum(state, data) {
      state.globalData.curLayerNum = data;
    },
    setCurLayerFan(state, data) {
      state.globalData.curLayerFan = data;
    },
    setCurUniqueWebcamSerial(state, data) {
      state.globalData.curUniqueWebcamSerial = data;
    },
    setCurWebcamSerial(state, data) {
      state.globalData.curWebcamSerial = data;
    },
    setCurPumpNo(state, data) {
      state.globalData.curPumpNo = data;
    },
    setCurPumpStatus(state, data) {
      state.globalData.curPumpStatus = data;
    },
    setCurEnvSensorNo(state, data) {
      state.globalData.curEnvSensorNo = data;
    },
    setCurWaterSensorNo(state, data) {
      state.globalData.curWaterSensorNo = data;
    },
    setMachineSensorNum(state, data) {
      state.globalData.pumpNum = data.pumpNum;
      state.globalData.curPumpNo = data.curPumpNo;
      state.globalData.pumpList = data.pumpList;
      state.globalData.envSensorNum = data.envSensorNum;
      state.globalData.curEnvSensorNo = data.curEnvSensorNo;
      state.globalData.envSensorList = data.envSensorList;
      state.globalData.waterSensorNum = data.waterSensorNum;
      state.globalData.curWaterSensorNo = data.curWaterSensorNo;
      state.globalData.waterSensorList = data.waterSensorList;
    },
    setMachineLoop(state, data) {
      state.globalData.machineLoop = data;
    },
  },
  actions: {
    updateCurUserName(context,data){
      context.commit('setCurUserName',data);
    },
    updateCurGroupName(context,data){
      context.commit('setCurGroupName',data);
    }
  },
  modules: {
  },
  //把数据存储到本地 localStorage
  plugins: [createPersistedState()],
  //把数据存储到本地 session
  // plugins: [createPersistedState({
  //   storge: window.sessionStorage
  // })],
})

export default store;
